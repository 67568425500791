const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.saas.forwoodsafety.com',
    APP_CLIENT_ID: '1fnifav1n2qhbkv1k7j36emll6',
    USER_TOKEN_URL: 'https://ljy8f1qyf4.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://7y4e6vesil.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.staging.saas.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.staging.saas.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.staging.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://8hbdhbgchl.execute-api.us-west-2.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;